<template>
         <kefu />

    <div style="height:100%">
        <div style="background:#ECF5FF	;width:514px;height:100%;float:left;">
            <div style="padding:64px 30px 64px 64px;height:261.5px">
                <a @click="to('/index')" class="logo">
                    <b>浩 瀚</b>
                    <!-- <b>非凡地推</b> -->
                </a>
                <b class="nb" >迄今为止，更好的资源！</b>
            </div>
            <div v-bind:style="'height:'+imgHeight+'px;max-height:601px;min-height:487px;margin-top:'+imgTop+'px'">
                <!-- <div :style="bg" ></div> -->
            </div>
        </div>
        <div style="overflow: hidden; ">
            <div style="height:50px;padding:15px 30px 0;text-align:right">
                <p>已有账号？
                    <a style="color:#4f3cc9"  @click="to('/login')">去登陆</a>
                </p>
            </div>
            <div :style="'padding-top:'+mainTop+'px'">
                <div style="padding:30px 60px 0;margin:auto;width:536px">
                <div>
                    <h2>注册</h2>
                    <!-- <div>
                        <a class="wechat">
                            <a-icon type="wechat" />
                        </a>
                    </div> -->
                    <a-divider style="margin-top:40px">Or</a-divider>
                    <div style="margin-bottom:12px">
                         <label>手机号</label>
                        <input type="text" v-model="phone" autocomplete="new-password"  tabindex="1" class="text-input" autocorrect="off" autocapitalize="off">
                    </div>


                    <!-- <div style="margin-bottom:12px">
                         <label>验证码</label>
                        <input type="text" v-model="smsCode" autocomplete="new-password" style="width:300px"  tabindex="2" class="text-input" autocorrect="off" autocapitalize="off">
                        <a-button @click="sendSms" class="sendBtn" :disabled="secods>0" >
                            <template #icon ><MailOutlined /></template>
                            {{secods>0?secods+' s':'发送'}}
                        </a-button>
                    </div> -->

                    <div style="margin-bottom:12px">
                         <label>密码 </label>
                        <input type="password" v-model="password" autocomplete="new-password"  tabindex="2" class="text-input" autocorrect="off" autocapitalize="off">
                    </div>
                    <div style="margin-bottom:12px">
                         <label>邀请码</label>
                        <input type="text" v-model="invitationCode"   tabindex="3" class="text-input" autocorrect="off" autocapitalize="off">
                    </div>
                    <a-button @click="register" class="loginBtn">
                        注册
                    </a-button>
                    <!-- <input class="loginBtn" :disabled="false" type="button" value="注册" tabindex="3"> -->
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { MailOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import kefu from  '../components/kefu'

import {memberSendSms,memberRegister} from '@/request.js'
const phoneReg = /^1[3-9]\d{9}$/

export default {
   name: '',
    components:{kefu},
    data() {
        return {
            invitationCode:"",
            agentId:0,
            secods:0,
            phone:"",
            smsCode:"",
            password:"",
            imgTop:0,
            mainTop:0,
            imgHeight:0,
            bg:{
                backgroundImage:'url(' + require('../assets/test.jpg') + ')',
                width:'100%',
                height:'100%',
                backgroundRepeat:"no-repeat",
                backgroundPosition:"bottom center",
                backgroundSize:"cover",
                flexGrow:1,
            },
        };
    },
    methods: {
        to(path){
            this.$router.push(path)
        },
        register(){
            if(!phoneReg.test(this.phone)){
                message.warning("请填写正确的手机号");
            }
            else if(this.invitationCode==null||this.invitationCode==""){
                message.warning("请填写邀请码");
            }
            else if(this.password==null||this.password.length==0){
                message.warning("请填写密码");
            }else{
                memberRegister({
                    Phone:this.phone,
                    LoginPwd:this.password,
                    Captcha:this.smsCode,
                    Name:"",
                    Email:"",
                    QQ:"",
                    Sex:1,
                    InvitationCode:this.invitationCode,
                    AgentId:this.agentId,

                },(res)=>{
                    if(res.IsSuccess){
                        localStorage.setItem("token",res.Message);
                        localStorage.setItem("phone",this.phone);
                        this.to("/index");
                    }else{
                        message.error(res.Message);
                    }
                })
            }
        },
        sendSms(){
            if (!phoneReg.test(this.phone)){
                message.warning("请填写正确的手机号");
            }else{
                memberSendSms({
                    phone:this.phone
                },(res)=>{
                    if(res.IsSuccess){
                        this.secods=60;
                        let timer=setInterval(() => {
                            if(this.secods==0){
                                clearInterval(timer);
                            }else{
                                this.secods-=1;
                            }
                        }, 1000);
                    }else{
                         message.error(res.Message);
                    }
                })
            }
        }
    },
    created(){
        if(this.$route.query.code){
            this.invitationCode=this.$route.query.code;
        }
        if(this.$route.query.agentId){
            this.agentId=this.$route.query.agentId;
        }
        this.imgHeight=document.documentElement.clientHeight-336
        this.imgTop=(document.documentElement.clientHeight-862.5)/2-20;
        this.mainTop=parseInt(document.documentElement.clientHeight/4);
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                this.imgHeight=document.documentElement.clientHeight-336;
                this.imgTop=(document.documentElement.clientHeight-862.5)/2-20;
                this.mainTop=parseInt(document.documentElement.clientHeight/4);
            })()
        }
    }
};
</script>
<style scoped>
.sendBtn{
    height:38px;float:right
}
.sendBtn:focus{
    color: #1890ff;
    border-color: #40a9ff;
}
.sendBtn:hover {
    color: #1890ff;
    border-color: #40a9ff;
} 

.wechat{
    color:#6e6d7a;background-color:#f2f2f2;padding: 10px 16px;border: none;cursor: pointer;border-radius: 8px;opacity: 0.7;margin-left:270px
}
.wechat:hover{
    opacity: 1;
}
label{
        display: block;
    font: bold 15px/24px "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 14px 0 4px;
    color: #0d0c22;
}
label a {
    font-weight: normal;
    float: right;
    position: relative;
    font-size: 14px;
}
h2{
    font: bold 24px/29px "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 26px;
}
.nb{
  font-family:"Times New Roman",Georgia,Serif;
    color:#003D79	;font-size:30px;
    letter-spacing:3px;
}
.logo:hover{
    opacity: 1;
}
.logo{
    letter-spacing:2px;
    display: block;
    margin-bottom: 30px;
    color: inherit;
    text-decoration: underline;
    color:#003D79;
    text-decoration:none; 
    font-size: 26px;
}
</style>