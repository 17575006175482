<template>
     <kefu />
    <div style="height:100%">
        <div style="background:#ECF5FF	;width:514px;height:100%;float:left;">
            <div style="padding:64px 30px 64px 64px;height:261.5px">
                <a @click="to('/index')" class="logo">
                    <b>浩 瀚</b>
                    <!-- <b>非凡地推</b> -->
                </a>
                <b class="nb" >迄今为止，更好的资源！</b>
            </div>
            <div v-bind:style="'height:'+imgHeight+'px;max-height:601px;min-height:487px;margin-top:'+imgTop+'px'">
                <!-- <div :style="bg" ></div> -->
            </div>
        </div>
        <div style="overflow: hidden; ">
            <div style="height:50px;padding:15px 30px 0;text-align:right">
                <p>没有账号？
                    <a style="color:#4f3cc9" @click="to('/register')">去注册</a>
                </p>
            </div>
            <div :style="'padding-top:'+mainTop+'px'">
                <div style="padding:30px 60px 0;margin:auto;width:536px">
                <div>
                    <h2>登录</h2>
                    <div>
                    </div>
                    <a-divider style="margin-top:40px">Or</a-divider>
                    <div style="margin-bottom:12px">
                        <label>手机号</label>
                        <input type="text" v-model="account"  autocomplete="new-password" tabindex="1" class="text-input" autocorrect="off" autocapitalize="off">
                    </div>
                    <div style="margin-bottom:12px">
                         <label>密码
                             <!-- <router-link :to="{name:'home'}">  -->
                             <!-- <a  @click="to('/forgot')">没有账号？去注册</a> -->
                         </label>
                        <input type="password"   v-model="password"  autocomplete="new-password" tabindex="2" class="text-input" autocorrect="off" autocapitalize="off" @keyup.enter="loginAction()">
                    </div>
                    <a-button @click="loginAction"  class="loginBtn">
                        登录
                    </a-button>
                </div>
            </div>
            </div>
            
        </div>

        
    </div>
</template>

<script>
import { memberLogin } from '@/request.js'
import { message } from 'ant-design-vue';
import kefu from  '../components/kefu'

export default {
    components:{kefu},

   name: 'fash',
    data() {
        
        return {
            account:"",
            password:"",
            imgTop:0,
            mainTop:0,
            imgHeight:0,
            bg:{
                backgroundImage:'url(' + require('../assets/test.jpg') + ')',
                width:'100%',
                height:'100%',
                backgroundRepeat:"no-repeat",
                backgroundPosition:"bottom center",
                backgroundSize:"cover",
                flexGrow:1,
            },
        };
    },
    methods: {
        loginAction(){
            memberLogin({
                phone:this.account,
                password:this.password,
            },(res)=>{
                if(res.IsSuccess){
                    localStorage.setItem("token",res.Message);
                    localStorage.setItem("phone",this.phone);
                    this.to("/pushtask");
                }else{
                    message.error(res.Message);
                }
            })
        },
        to(path){
            this.$router.push(path)
        }
    },
    created(){
        
        this.imgHeight=document.documentElement.clientHeight-336;
        this.imgTop=(document.documentElement.clientHeight-862.5)/2-20;
        this.mainTop=parseInt(document.documentElement.clientHeight/4);
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                this.imgHeight=document.documentElement.clientHeight-336;
                this.imgTop=(document.documentElement.clientHeight-862.5)/2-20;
                this.mainTop=parseInt(document.documentElement.clientHeight/4);
            })()
        }
        if(localStorage.getItem("token")!=null){
            this.to("/index");
        }
    }
};
</script>
<style scoped>


.wechat{
    color:#6e6d7a;background-color:#f2f2f2;padding: 10px 16px;border: none;cursor: pointer;border-radius: 8px;opacity: 0.7;margin-left:270px
}
.wechat:hover{
    opacity: 1;
}
label{
        display: block;
    font: bold 15px/24px "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 14px 0 4px;
    color: #0d0c22;
}
label a {
    font-weight: normal;
    float: right;
    position: relative;
    font-size: 14px;
}
h2{
    font: bold 24px/29px "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 26px;
}
.nb{
  font-family:"Times New Roman",Georgia,Serif;
    color:#003D79	;font-size:30px;
    letter-spacing:3px;
}
.logo:hover{
    opacity: 1;
}
.logo{
    letter-spacing:2px;
    display: block;
    margin-bottom: 30px;
    color: inherit;
    text-decoration: underline;
    color:#003D79;
    text-decoration:none; 
    font-size: 26px;
}
</style>